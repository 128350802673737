/*
 * site & site_content are used to push the footer to
 * the bottom of the page
 */
.site {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.site__content {
  flex: 1 0 auto; /* using auto fixes a bug in IE11 where the footer would float over everything. */
}

.site-office__content {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

footer {
  flex-shrink: 0;
}

.my-move-container {
  padding-top: 20px;
}

.my-move-container .usa-alert {
  margin-top: 0px;
}

img._fix-ie-11-height {
  max-height: 80px; /* After using auto in .site__content.flex, the transcom logo in IE11 on win8 was not constrained vertically. */
}

.todo {
  background-color: yellow;
}

.align-center {
  text-align: center;
}

.wider-label label {
  max-width: 100rem;
}

.grey {
  color: rgb(100, 100, 100);
}

.usa-input-label {
  margin-bottom: 1rem;
}

.rounded select {
  background-color: rgb(248, 248, 248);
  border-radius: 7px;
}

.city_state_zip {
  display: inline-block;
}
.city_state_zip input {
  display: inline-block;
  width: 18rem;
}
.city_state_zip select {
  display: inline-block;
}

.address_inline {
  display: inline-block;
}

.align-right {
  text-align: right !important;
}

.align-center-vertical {
  display: flex;
  align-items: center;
}
