html,
body,
#root {
  height: 100%;
  //font-size: 1rem;
}

body {
  margin: 0;
  padding: 0;
}

.inline_radio {
  margin-left: 4px;
  display: inline-block;
  margin-right: 10px;
}
