.icon {
  margin: 0 0.3em 0 0.5em;
}

.link-blue {
  color: #0071bc;
}

.approval-ready {
  color: green;
  cursor: pointer;
}

.approved {
  color: green;
}

.approval-waiting {
  color: #cd3504;
}

.approval-blocked {
  color: gray;
}

.approval-problem {
  color: #cd3504;
}

.shipment-line-item-warning {
  color: #cd3504;
  font-style: italic;
}
